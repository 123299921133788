import store from '@/store';
import {
    mdiAccountCheckOutline,
    mdiAccountOutline,
    mdiAccountPlusOutline,
    mdiAccountRemoveOutline,
    mdiCogOutline,
    mdiDatabaseOutline,
    mdiDnsOutline,
    mdiPencilOutline,
} from '@mdi/js';
import { ref, watch } from '@vue/composition-api';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';

export default function useUsersList() {
    const userListTable = ref([]);

    const tableColumns = [
        { text: i18n.t('Name'), value: 'name', class: 'text-uppercase' },
        { text: i18n.t('Email'), value: 'email', class: 'text-uppercase' },
        { text: i18n.t('Role'), value: 'role.name', class: 'text-uppercase' },
        { text: i18n.t('Parks'), value: 'parkingLots', class: 'text-uppercase' },
        {
            text: '',
            value: 'actions',
        },
    ];

    const searchQuery = ref('');
    const roleFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const totalUserListTable = userListTable.length;
    const loading = ref(false);
    const options = ref({
        sortBy: ['id'],
        sortDesc: [true],
    });
    const userTotalLocal = ref([]);
    const selectedRows = ref([]);

    // fetch data
    const fetchUsers = () => {
        store
            .dispatch('app-user/fetchUsers', {
                ...(searchQuery.value && { q: searchQuery.value }),
            })
            .then((response) => {
                //const { filteredData, total, userTotal } = response.data;

                userListTable.value = response.data.data;
                totalUserListTable.value = response.data.meta.pagination.total;
                //userTotalLocal.value = userTotal;

                // remove loading state
                loading.value = false;
            })
            .catch((error) => {
                console.log(error);
            });
    };

    watch([searchQuery, roleFilter, planFilter, statusFilter, options], () => {
        loading.value = true;
        selectedRows.value = [];
        fetchUsers();
    });

    const resolveUserPicture = (picture) => {
        if (_.get(picture, 'formats.thumbnail.url')) return _.get(picture, 'formats.thumbnail.url');

        if (_.get(picture, 'url')) return _.get(picture, 'url');
        return null;
    };
    const resolveUserPictureMax = (picture) => {
        if (_.get(picture, 'formats.large.url')) return _.get(picture, 'formats.large.url');

        if (_.get(picture, 'url')) return _.get(picture, 'url');
        return null;
    };
    const resolveUserRoleVariant = (role) => {
        if (role === 'subscriber') return 'primary';
        if (role === 'author') return 'warning';
        if (role === 'maintainer') return 'success';
        if (role === 'editor') return 'info';
        if (role === 'admin') return 'error';

        return 'primary';
    };

    const resolveUserRoleIcon = (role) => {
        if (role === 'subscriber') return mdiAccountOutline;
        if (role === 'author') return mdiCogOutline;
        if (role === 'maintainer') return mdiDatabaseOutline;
        if (role === 'editor') return mdiPencilOutline;
        if (role === 'admin') return mdiDnsOutline;

        return mdiAccountOutline;
    };

    const resolveUserStatusVariant = (status) => {
        if (status === 'pending') return 'warning';
        if (status === 'active') return 'success';
        if (status === 'inactive') return 'secondary';

        return 'primary';
    };

    const resolveUserTotalIcon = (total) => {
        if (total === 'Total Users') return { icon: mdiAccountOutline, color: 'primary' };
        if (total === 'Paid Users') return { icon: mdiAccountPlusOutline, color: 'error' };
        if (total === 'Active Users') return { icon: mdiAccountCheckOutline, color: 'success' };
        if (total === 'Pending Users') return { icon: mdiAccountRemoveOutline, color: 'warning' };

        return { icon: mdiAccountOutline, color: 'primary' };
    };

    const limitUserText = (user) => {
        if (user.length > 1) {
            return ' + ' + (user.length - 1);
        }
    };

    return {
        userListTable,
        tableColumns,
        searchQuery,
        roleFilter,
        planFilter,
        statusFilter,
        totalUserListTable,
        loading,
        options,
        userTotalLocal,
        selectedRows,
        fetchUsers,
        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        resolveUserTotalIcon,
        resolveUserPicture,
        resolveUserPictureMax,
        limitUserText,
    };
}
